// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agb-js": () => import("./../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-business-js": () => import("./../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-group-js": () => import("./../src/pages/group.js" /* webpackChunkName: "component---src-pages-group-js" */),
  "component---src-pages-imprint-js": () => import("./../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-incentive-js": () => import("./../src/pages/incentive.js" /* webpackChunkName: "component---src-pages-incentive-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-private-js": () => import("./../src/pages/private.js" /* webpackChunkName: "component---src-pages-private-js" */),
  "component---src-pages-schooltrip-js": () => import("./../src/pages/schooltrip.js" /* webpackChunkName: "component---src-pages-schooltrip-js" */)
}

